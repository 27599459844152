import React, {useEffect, useState} from "react";
import './detail.scss'
import LoadingIndicator from "../../components/loadingindicator/LoadingIndicator";
import Popup from "../../components/popup/Popup";
import Api from "../../util/Api";
import BarWithButton from "../../components/barwithbutton/BarWithButton";
import NavigationBar from "../../components/navigationbar/NavigationBar";
import TextPage from "../../util/models/TextPage";
import JsonObject from "../../util/json/JsonObject";
import {useHistory} from "react-router-dom";

function Detail(props) {
    const history = useHistory();
    const pageId = props.pageId || parseInt(props.match.params.id);
    const apiUrl = props.apiUrl || ('/textpage/' + pageId);
    const [page, setPage] = useState(/** @type {TextPage|null} */ null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(setData, []);

    function PageContent() {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="row">
                        <div id="content">
                            { page.imageUrl && <img className={"header-image"} src={page.imageUrl} alt={""}/> }
                            <div className={"text"} dangerouslySetInnerHTML={{__html: page.htmlText}} />
                        </div>
                    </div>
                </div>
                { page.buttonTitle && page.getRealButtonUrl() && <BarWithButton buttonTitle={page.buttonTitle} buttonLink={page.getRealButtonUrl()} buttonLinkTarget={"_blank"} /> }
            </div>
        );
    }

    return (
        <div id="detail" className="main">
            <NavigationBar title={page?.title || ''}
                           reference={true}
                           leftIcon={"back-arrow"}
                           leftIconClick={() => {
                               history.goBack();
                           }}
            />
            <LoadingIndicator isLoading={isLoading} indicatorColor={"dark"} showBackgroundOverlay={false}
                              isFullscreen={false}/>
            {page && <PageContent />}
        </div>
    );

    function setData() {
        if (!page) {
            getPage();
        }
    }

    function getPage() {
        function validator(response) {}

        function onSuccess(response) {
            setIsLoading(false)

            try {
                const textPageJsonObject = new JsonObject(response.data);
                const textPage = TextPage.parse(textPageJsonObject);
                setPage(textPage);
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getPage();
                },
                function () {
                    // Do nothing
                }
            )
        }

        setIsLoading(true);
        Api.get(apiUrl, validator, onSuccess, onFailure);
    }
}

export function ProblematicWasteStreamDetail(props) {
    const pageId = parseInt(props.match.params.id);
    return <Detail
        pageId={pageId}
        apiUrl={'/wastestream/' + pageId}
    />
}

export function AboutDetail(props) {
    const pageId = parseInt(props.match.params.id);
    return <Detail
        pageId={pageId}
        apiUrl={'/about/' + pageId}
    />
}

export function ReferencesDetail(props) {
    const pageId = parseInt(props.match.params.id);
    const detailId = parseInt(props.match.params.detailId);
    return <Detail
        pageId={pageId}
        apiUrl={`/reference/${pageId}/detail/${detailId}`}
    />
}

export function DatabasesDetail(props) {
    const pageId = parseInt(props.match.params.id);
    const detailId = parseInt(props.match.params.detailId);
    return <Detail
        pageId={pageId}
        apiUrl={`/database/${pageId}/detail/${detailId}`}
    />
}

export default Detail;