import React, {useEffect, useState} from "react";
import './databases.scss'
import Popup from "../../components/popup/Popup";
import Api from "../../util/Api";
import BaseList, {ObjectListItem, PDFListItem, TextPageListItem} from "../baselist/BaseList";
import JsonArray from "../../util/json/JsonArray";
import JsonObject from "../../util/json/JsonObject";
import ValidationHelper from "../../util/ValidationHelper";
import {useHistory} from "react-router-dom";
import DatabaseObject from "../../util/models/DatabaseObject";
import TextPage from "../../util/models/TextPage";
import SearchView from "../../components/searchview/SearchView";
import EmptyView from "../../components/emptyview/EmptyView";

function Databases(props) {
    const databaseObjectId = props.match.params.id;
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [databaseObjectsArray, setDatabaseObjectsArray] = useState(null);
    const [databaseObject, setDatabaseObject] = useState(null);
    const [databaseTextPageArray, setDatabaseTextPageArray] = useState(null);
    const [currentSearchQuery, setCurrentSearchQuery] = useState("");
    const pageKey = databaseObjectId ? databaseObjectId : "main-page";
    const showSecondaryList = ValidationHelper.exists(databaseObjectId) && databaseTextPageArray
    const showBackButton = ValidationHelper.exists(databaseObjectId)
    const databaseObjectIdExists = ValidationHelper.exists(databaseObjectId)

    const [emptyViewText, setEmptyViewText] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(setData, [databaseObjectId]);

    return (
        <BaseList key={pageKey}
                  className={"databases"}
                  title={getPageTitle()}
                  list={getList()}
                  data={getListData()}
                  isLoading={isLoading}
                  showBackButton={showBackButton}
                  didTapBackButton={resetSecondaryListData}
        />
    );

    function setData() {
        if (databaseObjectIdExists) {
            if(props.location.state && props.location.state.databaseObject) {
                setDatabaseObject(DatabaseObject.castToDatabaseObject(props.location.state.databaseObject))
            }
            getDatabases(databaseObjectId)
        } else if (!databaseObjectsArray) {
            getDatabases();
        }
    }

    function getPageTitle() {
        if(databaseObjectIdExists) {
            return databaseObject ? databaseObject.title : ""
        } else {
            return "Databases"
        }
    }

    function getListData() {
        if (showSecondaryList) {
            return databaseTextPageArray.items;
        } else if(databaseObjectsArray) {
            return databaseObjectsArray
        }
    }

    function resetSecondaryListData() {
        setDatabaseTextPageArray(null)
    }

    function getList() {
        if (showSecondaryList) {
            return <TextPageList/>
        } else {
            return <DatabaseObjectList/>
        }
    }

    function DatabaseObjectList() {
        if (!databaseObjectsArray) {
            return null
        }

        let listHtml = [];
        for (/** @type {DatabaseObject} */ const databaseObjectItem of databaseObjectsArray) {
            listHtml.push(
                <ObjectListItem key={databaseObjectItem.id}
                                title={databaseObjectItem.title}
                                object={databaseObjectItem}
                                onClick={(object) => {
                                    didTapListItem(object)
                                }}
                />
            );
        }

        return (
            <React.Fragment>
                { isLoading ? "" : listHtml.length === 0 ? <EmptyView /> :
                    <div className={"list row"}>
                        {listHtml}
                    </div> }
            </React.Fragment>
        )
    }

    function TextPageList() {
        if (!databaseTextPageArray) {
            return null
        }

        let listHtml = [];

        /** @type {TextPage} */
        databaseTextPageArray.forEach(textPage => {
            if (textPage.isTypePdf()) {
                listHtml.push(
                    <PDFListItem key={textPage.id}
                                 documentUrl={textPage.documentUrl}
                                 title={textPage.title}
                    />
                );
            } else if (textPage.isTypeTextPage()) {
                listHtml.push(
                    <TextPageListItem key={textPage.id}
                                      url={`/referenceapp/databases/${databaseObjectId}/detail/${textPage.id}`}
                                      title={textPage.title}
                    />
                );
            }
        })

        return (
            <div className={"list-container"}>
                <SearchView defaultValue={currentSearchQuery}
                            onChange={searchViewOnChange}
                />
                { isLoading ? "" : listHtml.length === 0 ? <EmptyView text={emptyViewText}/> :
                <div className={"list row"}>
                    {listHtml}
                </div> }
            </div>
        )
    }

    function didTapListItem(object) {
        if (object instanceof DatabaseObject) {
            setDatabaseObjectsArray(null);
            loadSecondaryList(object)
        }
    }

    function loadSecondaryList(object) {
        history.push(`/referenceapp/databases/${object.id}`, {databaseObject: object})
    }

    function searchViewOnChange(event) {
        const searchQuery = event.target.value;
        setCurrentSearchQuery(searchQuery);
        clearTimeout(searchTimeout);

        function delayedRequest() {
            setIsLoading(true);

            setEmptyViewText(null);

            setSearchTimeout(setTimeout(() => {
                getDatabases(databaseObjectId, searchQuery)
            }, 500));
        }

        if (searchQuery.length === 0) {
            setDatabaseTextPageArray([]);
            delayedRequest();
        } else if (searchQuery.length >= 1 && searchQuery.length < 3) {
            setIsLoading(false);

            setDatabaseTextPageArray([]);

            setEmptyViewText("Minimum of 3 characters to search");
        } else {
            delayedRequest();
        }
    }

    function getDatabases(databaseId, searchQuery = null) {
        setIsLoading(true);

        function validator(response) {
        }

        function onSuccess(response) {
            setIsLoading(false);
            try {
                if (databaseObjectIdExists) {
                    setDatabaseObject(parseDatabaseObject(response.data))
                    setDatabaseTextPageArray(parseDatabaseTextPages(response.data))
                } else {
                    setDatabaseObjectsArray(parseDatabaseList(response.data));
                }
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getDatabases();
                },
                function () {
                    // Do nothing
                }
            )
        }

        let databaseUrl
        if(databaseObjectIdExists) {
            const query = searchQuery && searchQuery.length > 0 ? `?q=${encodeURI(searchQuery)}` : ""
            databaseUrl = `/database/${databaseId}` + query
        } else {
            databaseUrl = '/database'
        }

        Api.get(databaseUrl, validator, onSuccess, onFailure)
    }

    function parseDatabaseList(jsonData) {
        const databaseObjectArray = [];
        const jsonArray = new JsonArray(jsonData);
        jsonArray.jsonData.forEach(itemJsonData => {
            const parsedDatabaseObject = parseDatabaseObject(itemJsonData)
            databaseObjectArray.push(parsedDatabaseObject)
        })

        return databaseObjectArray;
    }

    function parseDatabaseObject(jsonData) {
        const jsonObject = new JsonObject(jsonData)
        const parsedDatabaseObject = DatabaseObject.parse(jsonObject)
        return parsedDatabaseObject;
    }

    function parseDatabaseTextPages(jsonData) {
        const items = [];
        const textPageJsonArray = new JsonArray(jsonData.items);
        if (textPageJsonArray && textPageJsonArray.jsonData && Array.isArray(textPageJsonArray.jsonData)) {
            textPageJsonArray.jsonData.forEach((itemJsonData) => {
                const itemJsonObject = new JsonObject(itemJsonData)
                const textPage = TextPage.parse(itemJsonObject);
                items.push(textPage);
            })
        }
        return items;
    }
}

export default Databases;