import React from "react";
import './appselection.scss'
import Icon from "../util/Icon";
import {Link} from "react-router-dom";

function AppSelection() {

    return (
        <div id="appselection" className="main">
            <div className="page-content">
                <div className={"top-half"}>
                    <div className={"half-content row with-margin"}>
                        <div className={"logo-row"}>
                            <img alt="Sweap" className="circle-logo" src={require('../resources/sweap-circle.png')}/>
                        </div>
                        <div className="title-wrapper">
                            <span className="title">SWEAP</span>
                            <span className="subtitle">Inspection Form</span>
                        </div>
                        <Link to={'/login'} className={"button-link"}>
                            <div className="button">
                                <div className="text roboto-regular">Login</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={"bottom-half"}>
                    <div className={"half-content row with-margin"}>
                        <div className="logo-row">
                            <img alt="Sweap" className="circle-logo" src={require('../resources/sweap-circle.png')}/>
                            <img alt="Waste force" className="circle-logo" src={require('../resources/waste_force_circle.svg')}/>
                        </div>
                        <div className="title-wrapper">
                            <span className="title">Inspection</span>
                            <span className="subtitle">Reference App</span>
                        </div>
                        <Link to={'/referenceapp/problematicwastestreams'} className={"button-link"}>
                            <div className="button reference">
                                <div className="text roboto-regular">Open</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppSelection;