import JsonObject from "../json/JsonObject";
import WasteCodeDescription from "./WasteCodeDescription";

class WasteCodeObject {
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.descriptions = [];
        const itemArray = jsonObject.obj("descriptions")
        if (itemArray && itemArray.jsonData && Array.isArray(itemArray.jsonData)) {
            itemArray.jsonData.forEach((itemJsonData) => {
                const itemJsonObject = new JsonObject(itemJsonData)
                const wasteCodeDescription = WasteCodeDescription.parse(itemJsonObject);
                this.descriptions.push(wasteCodeDescription);
            })
        }
    }

    static parse(jsonObject) {
        const wasteCodeObject = new WasteCodeObject();
        wasteCodeObject.parse(jsonObject);
        return wasteCodeObject;
    }
}
export default WasteCodeObject;