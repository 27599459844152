import JsonError from "./JsonError";

class JsonObject {
    constructor(json) {
        if(!JsonObject.isJsonObject(json)) {
            throw new JsonError("This is not a JsonObject");
        }

        this.jsonData = json;
    }

    /**
     * @param {string} name
     * @param {JsonObject} defaultValue
     * @returns {JsonObject}
     */
    objDefault(name, defaultValue) {
        const jsonValue = this.objNull(name);
        return (jsonValue == null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {JsonObject|null}
     */
    objNull(name) {
        try {
            return this.obj(name);
        } catch (e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {JsonObject}
     * @throws
     */
    obj(name) {
        const jsonValue = this.jsonData[name];
        if(!JsonObject.isJsonObject(jsonValue)) {
            throw new JsonError(name + " is not a JsonObject");
        }
        return new JsonObject(jsonValue);
    }

    /**
     * @param {string} name
     * @param {string} defaultValue
     * @returns {string}
     */
    stringDefault(name, defaultValue) {
        const jsonValue = this.stringNull(name);
        return (jsonValue == null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {null|string}
     */
    stringNull(name) {
        try {
            return this.string(name);
        } catch (e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {string}
     * @throws
     */
    string(name) {
        const jsonValue = this.jsonData[name];
        if(jsonValue == null) {
            throw new JsonError(name + " is not a string");
        }
        if(typeof jsonValue === 'string') {
            return jsonValue;
        }
        return "" + jsonValue;
    }

    /**
     * @param {string} name
     * @param {int} defaultValue
     * @returns {int}
     */
    intDefault(name, defaultValue) {
        const jsonValue = this.intNull(name);
        return (jsonValue == null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {null|int}
     */
    intNull(name) {
        try {
            return this.int(name);
        } catch (e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {int}
     * @throws
     */
    int(name) {
        let jsonValue = this.jsonData[name];
        if(jsonValue != null) {
            if(typeof jsonValue === 'string') {
                jsonValue = Number(jsonValue);
            }
            if(typeof jsonValue === 'number' && !isNaN(jsonValue)) {
                return Math.trunc(jsonValue);
            }
        }
        throw new JsonError(name + " is not an int");
    }

    /**
     * @param json
     * @returns {boolean}
     */
    static isJsonObject(json) {
        return (json != null && typeof json === 'object');
    }
}
export default JsonObject;