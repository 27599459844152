import React from "react";
import './navigationbar.scss'
import Icon from "../../util/Icon";

function NavigationBar(props) {

    const LeftIcon = () => {
        return props.leftIcon
            ? <div onClick={props.leftIconClick}><HeaderIcon iconName={props.leftIcon}/></div>
            : null
    };

    const RightIcon = () => {
        return props.rightIcon
            ? <div onClick={props.rightIconClick}><HeaderIcon iconName={props.rightIcon}/></div>
            : null
    };

    return (
        <div id="navigation-bar" className={(props.reference === true) ? "reference" : ""}>
            <div id="navigation-bar-content" className="row with-margin">
                <div className={"icon-container"}>
                    <LeftIcon/>
                </div>
                <h1>{props.title}</h1>
                <div className={"icon-container"}>
                    <RightIcon/>
                </div>
            </div>
        </div>
    );
}

function HeaderIcon(props) {
    const isBackArrow = props.iconName === 'back-arrow';
    let style;
    if(isBackArrow) {
        style = {
            maxWidth: 14
        }
    }

    return (
        <Icon size={23} icon={props.iconName} className={'icon'} style={style}/>
    )
}

export default NavigationBar;