import React, {useState} from "react";
import './inputfields.scss'
import AsyncSelect from 'react-select/async'

function AutoCompleteTextField(props) {
    const [optionsVisible, setOptionsVisible] = useState(props.optionsVisible);
    const [isLoadError, setLoadError] = useState(false);

    props.optionsVisibleFunction(setOptionsVisible, optionsVisible)
    props.setLoadErrorFunction(setLoadError, isLoadError)

    return (
        <div className="form-field">
            <div className="label-holder">
                <label htmlFor={props.name}>{props.title}</label>
            </div>
            <AsyncSelect
                name={props.name}
                isDisabled={props.readOnly}
                className={props.name}
                classNamePrefix="impel-select"
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null
                }}
                defaultValue={props.currentValue}
                loadOptions={props.onLoadOptions}
                isSearchable={true}
                isClearable={true}
                placeholder={props.placeholderText}
                noOptionsMessage={() => {
                    if (optionsVisible) {
                        if (isLoadError) {
                            return "Something went wrong. Please check your internet connection and try again.";
                        } else {
                            return props.emptyStateText;
                        }
                    } else {
                        return null;
                    }
                }}
                onChange={props.onSelectItem}
            />
        </div>
    );
}

export default AutoCompleteTextField;