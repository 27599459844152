import React from "react";
import './dashboard.scss'
import AppStorage from "../util/AppStorage";
import {Link} from "react-router-dom";
import Header from "../inspection/header/Header";
import DownloadAppHint from "../components/downloadapphint/DownloadAppHint";
import AppFeatureList from "../components/appfeaturelist/AppFeatureList";
import NavigationBar from "../components/navigationbar/NavigationBar";
import SideMenu from "../components/sidemenu/SideMenu";

function Dashboard() {
    let setMenuVisible = null

    return (
        <div id="dashboard" className="main">
            <NavigationBar title="Inspections" leftIcon="menu" leftIconClick={() => {setMenuVisible(true)}}/>
            <div className="start-button">
                <Link to={'/inspection'} className="start-link" onClick={() => {
                    AppStorage.setCurrentStepIndex(null);
                    AppStorage.setInspectionForm(null);
                }}>
                    <div className="button">Start inspection</div>
                </Link>
            </div>
            <div className={"row with-margin"}>
                <DownloadAppHint/>
            </div>
            <AppFeatureList/>
            <div className="logout-button-footer">
                <Link to={'/home'} className="logout-link" onClick={() => AppStorage.logout()}>
                    Log out
                </Link>
            </div>
            <SideMenu menuVisibleFunction={(setMenuVisibleFunction) => {
                          setMenuVisible = setMenuVisibleFunction
                      }}/>
        </div>
    );
}

export default Dashboard;