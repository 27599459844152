import React, {useState} from "react";
import './sidemenu.scss'
import NavigationBar from "../navigationbar/NavigationBar";
import Icon from "../../util/Icon";
import {Link} from "react-router-dom";
import AppStorage from "../../util/AppStorage";

function SideMenu(props) {
    const isReference = props.reference;
    const [isVisible, setVisible] = useState(false);

    props.menuVisibleFunction(setVisible, isVisible)

    function Space() {
        return (
            <div style={{height: '40px'}}/>
        );
    }

    function SectionHeader(sectionHeaderProps) {
        return (
            <div className="section-header">{sectionHeaderProps.title}</div>
        );
    }

    function MenuItem(menuItemProps) {
        return (
            <Link to={menuItemProps.linkTo} onClick={() => {
                setVisible(false)
            }}>
                <div className="menu-item">
                    <div className="title">{menuItemProps.title}</div>
                    <div className="chevron">
                        <Icon size={14} icon={'arrow-right'} className={'icon'}/>
                    </div>
                </div>
            </Link>
        );
    }

    function MenuContent() {
        if(isReference) {
            return (
                <React.Fragment>
                    <Space/>
                    <MenuItem title="Problematic waste streams" linkTo="/referenceapp/problematicwastestreams"/>
                    <MenuItem title="Check the controls" linkTo="/referenceapp/checkthecontrols"/>
                    <MenuItem title="References" linkTo="/referenceapp/references"/>
                    <MenuItem title="Databases" linkTo="/referenceapp/databases"/>
                    <MenuItem title="About" linkTo="/referenceapp/about"/>
                    <Space/>
                    <SectionHeader title="SWEAP Inspection Form"/>
                    <MenuItem title="Open Inspection Form" linkTo={(AppStorage.isUserLoggedIn()) ? '/dashboard' : '/login'}/>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Space/>
                    <SectionHeader title="Inspection Reference Tool"/>
                    <MenuItem title="Open Reference Tool" linkTo="/referenceapp"/>
                </React.Fragment>
            )
        }
    }

    return (
        <div id="side-menu" className={isVisible ? 'visible' : ''}>
            <div className={"content" + (isReference ? ' reference' : '')}>
                <NavigationBar title="&nbsp;" reference={isReference} leftIcon="cross" leftIconClick={() => setVisible(false)}/>
                <div className="row">
                    <MenuContent/>
                </div>
            </div>
        </div>
    );
}

export default SideMenu;