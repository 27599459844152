import React, {useEffect, useState} from "react";
import Header from "../../inspection/header/Header";
import {Redirect, useHistory} from "react-router-dom";
import Api from "../../util/Api";
import ValidationError from "../../util/ValidationError";
import "./smartselect-createoption.scss"
import AppStorage from "../../util/AppStorage";
import Icon from "../../util/Icon";
import Popup from "../../components/popup/Popup";
import LoadingIndicator from "../../components/loadingindicator/LoadingIndicator";

function SmartSelectCreateOption(props) {
    const history = useHistory();
    const smartSelectId = parseInt(props.match.params.id);
    const form = AppStorage.getInspectionForm();
    const [countries, setCountries] = useState([]);

    let allQuestions = [];
    form.steps.forEach((step) => {
        allQuestions = allQuestions.concat(step.questions);
    });
    const smartSelectQuestion = allQuestions.find(q => q.id === smartSelectId);
    const [isLoading, setIsLoading] = useState(true);
    const [isPostingOption, setIsPostingOption] = useState(false);

    const shouldRedirect = !form || !smartSelectQuestion || !smartSelectId;
    useEffect(() => {
        if (!shouldRedirect) {
            getCountries();
        }
    }, []);

    if (shouldRedirect) {
        return (
            <Redirect to={'/inspection'}/>
        );
    }

    let inputName = null;
    const titleFieldLabel = <label>Name</label>;
    const titleField = <input type="text" className="form-field-input title"
                              onChange={e => inputName = e.target.value}/>;

    let inputCountryID = null;
    const countriesFieldLabel = <label>Country</label>;
    let options = countries.map((country) => {
            return <option key={"country-" + country.id} value={country.id}
                           onChange={(e) => inputName = e.target.value}>{country.name}</option>
        }
    );
    options.unshift(<option className="placeholder" key={"default-select"} value={null}>— SELECT —</option>);
    const countriesField = <select onChange={(e) => inputCountryID = e.target.value}>{options}</select>;

    return (
        <div id="smart-select-create-option" className="main">
            <Header title={"New " + smartSelectQuestion.titleSingular} leftIcon="back-arrow"
                    leftIconClick={goToPreviousPage}/>
            <div className="content-container">
                <div className="content row with-margin">
                    {titleFieldLabel}
                    {titleField}
                    {countriesFieldLabel}
                    <div className="select">
                        {countriesField}
                        <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="button" onClick={onCreateClick}>Create</div>
            </div>
            <LoadingIndicator isLoading={isLoading} indicatorColor={isPostingOption ? "light" : "dark"} showBackgroundOverlay={isPostingOption} isFullscreen={true}/>
        </div>
    );

    function getCountries() {
        function validator(response) {
            if (!response.data || !Array.isArray(response.data)) {
                throw new ValidationError("Smart Select countries validation error.");
            }
        }

        function onSuccess(response) {
            setIsLoading(false)
            setCountries(response.data);
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(getCountries, goToPreviousPage);
        }

        setIsLoading(true);
        let url = '/form/smartselect/' + smartSelectQuestion.id + '/' + form.referenceId + '/countries';
        Api.get(url, validator, onSuccess, onFailure);
    }

    function onCreateClick() {
        function showErrorPopup() {
            const config = {
                title: "Error in form",
                text: "Some required field are not filled in or incorrectly filled in",
                confirmButtonText: "OK"
            };
            Popup.show(config)
        }

        if (!inputName || inputName.length === 0) {
            showErrorPopup()
        } else if (isNaN(inputCountryID)) {
            showErrorPopup()
        } else {
            createOption();
        }
    }

    function createOption() {
        function validator(response) {
            if (!response.data || !response.data.id || !response.data.summary || !response.data.title) {
                throw new ValidationError("Smart Select post option validation error.");
            }
        }

        function onSuccess(response) {
            setIsPostingOption(false)
            setIsLoading(false);
            smartSelectQuestion.value = smartSelectQuestion.value ? smartSelectQuestion.value : [];
            const newlyCreatedOption = response.data;
            smartSelectQuestion.value.push(newlyCreatedOption);
            AppStorage.setInspectionForm(form);
            goToPreviousPage();
        }

        function onFailure(error) {
            setIsPostingOption(false)
            setIsLoading(false);
            if (error.response && error.response.status === 409) {
                const duplicatePopupConfig = {
                    title: "Duplicate entry",
                    text: "This " + smartSelectQuestion.titleSingular + " has already been created",
                    confirmButtonText: "OK"
                };
                Popup.show(duplicatePopupConfig);
            } else {
                Popup.showDefaultRequestError(createOption);
            }
        }

        setIsPostingOption(true)
        setIsLoading(true);
        const config = {
            data: {
                countryID: parseInt(inputCountryID),
                title: inputName,
            }
        };
        let url = '/form/smartselect/' + smartSelectQuestion.id + '/' + form.referenceId + '/options';
        Api.post(url, validator, onSuccess, onFailure, config);
    }

    function goToPreviousPage() {
        history.goBack();
    }
}

export default SmartSelectCreateOption