import React, {useEffect, useState} from "react";
import JsonArray from "../../util/json/JsonArray";
import TextPage from "../../util/models/TextPage";
import Popup from "../../components/popup/Popup";
import Api from "../../util/Api";
import BaseList, {PDFListItem, TextPageListItem} from "../baselist/BaseList";

function About() {
    const [isLoading, setIsLoading] = useState(false);
    const [textPages, setTextPages] = useState(null);

    useEffect(setData, []);

    return (
       <BaseList title={"About"}
                 list={<List/>}
                 data={textPages}
                 isLoading={isLoading}/>
    );

    function setData() {
        if (textPages === null) {
            getAboutTextPages();
        }
    }

    function List() {
        if(!textPages) {
            return null
        }

        let listHtml = [];
        for (/** @type {TextPage} */ const textPage of textPages) {
            if (textPage.isTypePdf()) {
                listHtml.push(
                    <PDFListItem key={textPage.id}
                                 documentUrl={textPage.documentUrl}
                                 title={textPage.title}
                    />
                );
            } else if (textPage.isTypeTextPage()) {
                listHtml.push(
                    <TextPageListItem key={textPage.id}
                                      url={"/referenceapp/about/" + textPage.id}
                                      title={textPage.title}
                    />
                );
            }
        }

        return <div className={"list row"}>
            {listHtml}
        </div>
    }

    function getAboutTextPages() {
        setIsLoading(true);

        function validator(response) {}

        function onSuccess(response) {
            setIsLoading(false);

            try {
                const textPageArray = [];
                const jsonArray = new JsonArray(response.data);
                for(let i = 0; i < jsonArray.jsonData.length; i++) {
                    const textPageJsonObject = jsonArray.getObj(i);
                    const textPage = TextPage.parse(textPageJsonObject);
                    textPageArray.push(textPage);
                }
                setTextPages(textPageArray);
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getAboutTextPages();
                },
                function () {
                    // Do nothing
                }
            )
        }

        Api.get('/about', validator, onSuccess, onFailure)
    }
}

export default About;