import Swal, {DismissReason} from "sweetalert2";
import './popup.scss'

class Popup {

    static show(config, confirm = null, cancel = null) {

        const finalConfig = {
            title: config.title ?? "",
            text: config.text ?? "",
            confirmButtonText: config.confirmButtonText ?? "",
            cancelButtonText: config.cancelButtonText ?? ""
        };

        const showCancelButton = finalConfig.cancelButtonText.length > 0;
        const fullWidthButtonClass = showCancelButton ? "" : " full-width-button"

        Swal.fire({
            title: finalConfig.title,
            html: finalConfig.text,
            reverseButtons: true,
            showCancelButton: showCancelButton,
            confirmButtonText: finalConfig.confirmButtonText,
            cancelButtonText: finalConfig.cancelButtonText,
            heightAuto: false,
            customClass: {
                popup: "custom-popup",
                container: "custom-container",
                content: "custom-content",
                header: "custom-header",
                actions: "custom-actions" + fullWidthButtonClass,
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button"
            }
        }).then(function (result) {
            if (showCancelButton && result.dismiss === DismissReason.cancel) {
                if(cancel !== null) {
                    cancel();
                }
            } else {
                if(confirm !== null) {
                    confirm();
                }
            }
        });
    }

    static showDefaultRequestError(confirm, cancel = null) {
        Popup.show({
                title: "Error",
                text: "An error occurred while fetching data, please try again.",
                confirmButtonText: "Retry",
                cancelButtonText: "Cancel"
            },
            confirm,
            cancel
        )
    }
}

export default Popup;