import React, {useEffect, useState} from "react";
import Popup from "../../components/popup/Popup";
import Api from "../../util/Api";
import BaseList, {ObjectListItem, PDFListItem, TextPageListItem} from "../baselist/BaseList";
import JsonArray from "../../util/json/JsonArray";
import ReferenceObject from "../../util/models/ReferenceObject";
import JsonObject from "../../util/json/JsonObject";
import ValidationHelper from "../../util/ValidationHelper";
import {useHistory} from "react-router-dom";

function References(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [referenceObjectsArray, setReferenceObjectArray] = useState(null);
    const [referenceObject, setReferenceObject] = useState(null);
    const pageKey = referenceObject ? referenceObject.id : "main-page";
    const showSecondaryList = props.match.params.id && referenceObject
    const showBackButton = props.match.params.id

    useEffect(setData, [props.match.params.id, props.location.state]);

    return (
        <BaseList key={pageKey}
                  title={"References"}
                  list={getList()}
                  data={getListData()}
                  isLoading={isLoading}
                  showBackButton={showBackButton}
                  didTapBackButton={resetSecondaryListData}
        />
    );

    function setData() {
        if (props.location.state && props.location.state.referenceObject) {
            setReferenceObject(ReferenceObject.castToReferenceObject(props.location.state.referenceObject))
        } else if (props.match.params.id) {
            getReferences(props.match.params.id)
        } else if (!referenceObjectsArray) {
            getReferences();
        }
    }

    function getListData() {
        if (showSecondaryList) {
            return referenceObject.items;
        } else {
            return referenceObjectsArray
        }
    }

    function resetSecondaryListData() {
        setReferenceObjectArray(null)
    }

    function getList() {
        if (showSecondaryList) {
            return <TextPageList/>
        } else {
            return <ReferenceObjectList/>
        }
    }

    function ReferenceObjectList() {
        if (!referenceObjectsArray) {
            return null
        }

        let listHtml = [];
        for (/** @type {ReferenceObject} */ const referenceObjectItem of referenceObjectsArray) {
            listHtml.push(
                <ObjectListItem key={referenceObjectItem.id}
                                title={referenceObjectItem.title}
                                object={referenceObjectItem}
                                onClick={(object) => {
                                    didTapListItem(object)
                                }}
                />
            );
        }

        return <div className={"list row"}>
            {listHtml}
        </div>
    }

    function TextPageList() {
        if (!referenceObject) {
            return null
        }

        let listHtml = [];

        /** @type {TextPage} */
        referenceObject.items.forEach(textPage => {
            if (textPage.isTypePdf()) {
                listHtml.push(
                    <PDFListItem key={textPage.id}
                                 documentUrl={textPage.documentUrl}
                                 title={textPage.title + "_PDF"}
                    />
                );
            } else if (textPage.isTypeTextPage()) {
                listHtml.push(
                    <TextPageListItem key={textPage.id}
                                      url={`/referenceapp/references/${referenceObject.id}/detail/${textPage.id}`}
                                      title={textPage.title}
                    />
                );
            }
        })

        return <div className={"list row"}>
            {listHtml}
        </div>
    }

    function didTapListItem(object) {
        if (object instanceof ReferenceObject) {
            loadSecondaryList(object)
        }
    }

    function loadSecondaryList(object) {
        history.push(`/referenceapp/references/${object.id}`, {referenceObject: object})
    }

    function getReferences(referenceId) {
        setIsLoading(true);

        function validator(response) {
        }

        function onSuccess(response) {
            setIsLoading(false);

            try {
                if (ValidationHelper.exists(referenceId)) {
                    setReferenceObject(parseReference(response.data))
                } else {
                    const referenceList = parseReferencesList(response.data);
                    if (referenceList.length === 1) {
                        //Immediately show secondary list, as there is only 1 item in the primary list
                        loadSecondaryList(referenceList[0])
                    } else {
                        setReferenceObjectArray(referenceList);
                    }
                }
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getReferences();
                },
                function () {
                    // Do nothing
                }
            )
        }

        const referenceUrl = ValidationHelper.exists(referenceId) ? `/reference/${referenceId}` : '/reference'
        Api.get(referenceUrl, validator, onSuccess, onFailure)
    }

    function parseReferencesList(responseData) {
        const referenceArray = [];
        const jsonArray = new JsonArray(responseData);
        jsonArray.jsonData.forEach(jsonData => {
            const reference = parseReference(jsonData)
            referenceArray.push(reference)
        })

        return referenceArray;
    }

    function parseReference(jsonData) {
        const jsonObject = new JsonObject(jsonData)
        const refObject = ReferenceObject.parse(jsonObject)
        return refObject;
    }
}

export default References;