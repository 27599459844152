import React from "react";
import AppStorage from "../util/AppStorage";
import { Redirect, Route } from "react-router-dom";

function AuthRoute(props) {
    if(!AppStorage.isUserLoggedIn()) {
        return <Redirect to={"/login"}/>
    }
    return <Route {...props} />;
}

export default AuthRoute;