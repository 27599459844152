import React, {useEffect, useState} from "react";
import './inspection.scss'
import Header from "./header/Header";
import {Redirect, useHistory} from "react-router-dom";
import AppStorage from "../util/AppStorage";
import Steps from "./steps/Steps";
import Icon from "../util/Icon";
import Form, {filterQuestionsOnDependencies} from "./form/Form";
import ValidationError from "../util/ValidationError";
import Api from "../util/Api";
import {QUESTION_TYPES} from "./form/question/Question";
import Popup from "../components/popup/Popup";
import LoadingIndicator from "../components/loadingindicator/LoadingIndicator";

function Inspection(props) {
    const history = useHistory();
    const [form, setForm] = useState(AppStorage.getInspectionForm());
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOverlayEnabled, setIsLoadingOverlayEnabled] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(AppStorage.getCurrentStepIndex() ? AppStorage.getCurrentStepIndex() : 0);
    const [forceUpdateCount, setForceUpdateCount] = useState(0);

    AppStorage.setCurrentStepIndex(currentStepIndex);

    useEffect(() => {
        if (!form) {
            setIsLoading(true);
            getInspectionForm();
        }
    }, []);

    AppStorage.setCurrentStepIndex(currentStepIndex);

    function FormContent() {

        if(!isLoadingOverlayEnabled && isLoading) {
            return <LoadingIndicator/>
        }

        if (!form) {
            return null;
        }

        let showingFinalStep = currentStepIndex === form.steps.length - 1;
        const isFirstStep = currentStepIndex === 0;
        const isFistStepClassName = isFirstStep ? " first-step" : "";

        return [
            isLoadingOverlayEnabled && isLoading && <LoadingIndicator key={"loading-indicator-post"} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>,
            <Steps key={"steps-key"} stepCount={form.steps.length} activeStep={currentStepIndex} onStepClick={index => {
                stepClicked(index)
            }}/>,
            <Form key={"form-key"} form={form}
                  activeStep={form.steps[currentStepIndex]}
                  setIsLoading={setIsLoading}
                  setIsLoadingOverlayEnabled={setIsLoadingOverlayEnabled}
                  onFieldChange={onFieldChange} onClick={onFormFieldClick}/>,
            <div key={"footer-key"} className="steps-footer">
                <div className={"row with-margin" + isFistStepClassName}>
                    {
                        (currentStepIndex !== 0) &&
                        <div className="step-footer-button left" onClick={() => {
                            stepClicked(currentStepIndex - 1)
                        }}>
                            <Icon size={15} icon="arrow-right" className='icon' color="black"/>
                            Step {currentStepIndex}
                        </div>
                    }
                    <div className="step-footer-button right" onClick={() => {
                        showingFinalStep ? finalize() : stepClicked(currentStepIndex + 1)
                    }}>
                        {showingFinalStep ? "Finalize" : "Step " + (currentStepIndex + 2)}
                        <Icon size={15} icon="arrow-right" className='icon' color="black"/>
                    </div>
                </div>
            </div>
        ];
    }

    return (
        <div id="inspection" className="main">
            <Header title="Inspections" rightIcon="cross" rightIconClick={showExitInspectionPopup}/>
            <FormContent/>
            {/*{isLoading ? <LoadingIndicator/> : <FormContent/>}*/}
        </div>
    );

    function getInspectionForm() {
        function validator(response) {
            //values can be null or undefined if not set at all, error is thrown in both cases
            let formResponse = response.data;
            let formMissesAttribute = !formResponse || !formResponse.id || !formResponse.referenceId || !formResponse.steps || !formResponse.steps.length;
            if (formMissesAttribute) {
                throw new ValidationError("Inspection form data is not valid.");
            }

            formResponse.steps.forEach(step => {
                let stepMissesAttribute = !step.id || !step.title || !step.questions;
                if (stepMissesAttribute) {
                    throw new ValidationError("Step data is not valid.");
                }

                step.questions.forEach(question => {
                    let questionMissesAttribute = !question.id || !question.type || !question.title || !question.options || !QUESTION_TYPES.includes(question.type);
                    if (questionMissesAttribute) {
                        throw new ValidationError("Question data is not valid.");
                    }
                });
            });
        }

        function onSuccess(response) {
            setIsLoading(false)
            setForm(response.data);
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getInspectionForm();
                },
                function () {
                    showDashboard();
                }
            )
        }

        setIsLoading(true);
        Api.get('/form', validator, onSuccess, onFailure);
    }

    function getInspectionType() {
        let allQuestions = [];
        form.steps.forEach((step) => {
            allQuestions = allQuestions.concat(step.questions);
        });
        let inspectionTypeQuestion = allQuestions.find((question) => {
            return question.dependencyKey === "inspectionType"
        });
        return inspectionTypeQuestion ? inspectionTypeQuestion.value : null;
    }

    function stepClicked(stepIndex) {
        if (stepIndex !== 0) {
            if (getInspectionType() === null || getInspectionType() === "null" || isNaN(getInspectionType())) {
                const config = {
                    title: "Select inspection type",
                    text: "Please select an inspection type before continuing to the next step.",
                    confirmButtonText: "OK"
                };

                Popup.show(config)
            } else {
                setCurrentStepIndex(stepIndex);
            }
        } else {
            setCurrentStepIndex(stepIndex);
        }
    }

    function onFieldChange(field, value) {
        if (field.type === "checkbox") {
            let currentValue = Array.isArray(field.value) ? field.value : [];

            function removeItemOnce(arr, removable) {
                let index = arr.indexOf(removable);
                if (index > -1) {
                    arr.splice(index, 1);
                }
                return arr;
            }

            if (currentValue.includes(value)) {
                currentValue = removeItemOnce(currentValue, value)
            } else {
                currentValue.push(value)
            }
            field.value = currentValue;
        } else {
            field.value = value;
        }

        AppStorage.setInspectionForm(form);
        if (field.dependencyKey) {
            setForceUpdateCount(forceUpdateCount + 1); //React hooks way to trigger a rerender
        }
    }

    function onFormFieldClick(formFieldProps) {
        if (formFieldProps.field && formFieldProps.field.type && formFieldProps.field.type === "smartselect") {
            showSmartSelectScreen(formFieldProps)
        }
    }

    function postFormAnswers() {
        function validator(response) {
            if (!(response.data.data && response.data.data.success)) {
                throw new ValidationError("Couldn't post successfully")
            }
        }

        function onSuccess(response) {
            setIsLoadingOverlayEnabled(false);
            setIsLoading(false);

            function goToDashboard() {
                AppStorage.setInspectionForm(null);
                AppStorage.setCurrentStepIndex(null);
                props.history.push("/dashboard");
            }

            const config = {
                title: "Inspection finalised",
                text: "You have successfully completed the inspection. The data is sent to the server.",
                confirmButtonText: "OK"
            };
            Popup.show(config, goToDashboard);
        }

        function onFailure() {
            setIsLoadingOverlayEnabled(false);
            setIsLoading(false);

            function tryAgain() {
                finalize();
            }

            const config = {
                title: "Unable to save form",
                text: "Please try again",
                confirmButtonText: "Retry",
                cancelButtonText: "Cancel"
            };
            Popup.show(config, tryAgain);
        }

        let formReply = {
            "inspectionType": getInspectionType(),
            "answers": []
        };

        let allQuestions = [];
        form.steps.forEach(step => allQuestions = allQuestions.concat(step.questions));
        let allRelevantQuestions = allQuestions.filter(q => filterQuestionsOnDependencies(allQuestions, q));
        allRelevantQuestions.forEach((q) => {
            if (q.type === "smartselect") {
                q.answer = (q.value ? q.value : []).map((v) => {
                    return {
                        "id": v.id,
                        "title": v.summary
                    }
                })
            } else {
                q.answer = q.value;
            }
        });
        allRelevantQuestions.forEach(q =>
            formReply.answers.push({
                'questionId': q.id,
                'type': q.type,
                'value': q.answer,
            })
        );

        setIsLoadingOverlayEnabled(true);
        setIsLoading(true);
        Api.post('/form/reply/' + form.referenceId, validator, onSuccess, onFailure, {}, formReply)
    }

    function finalize() {
        let allQuestions = [];
        form.steps.forEach(step => allQuestions = allQuestions.concat(step.questions));
        let allRelevantQuestions = allQuestions.filter(q => filterQuestionsOnDependencies(allQuestions, q));

        let allRequiredQuestion = allRelevantQuestions.filter(q => q.required);
        let allMissingRequiredQuestions = allRequiredQuestion.filter((q) => {
            let valueIsNull = !q.value || q.value === 'null';

            if (q.type === "checkbox" && Array.isArray(q.value)) {
                return q.value.length === 0;
            }
            return valueIsNull;
        });

        let extraInfoString = '';
        allMissingRequiredQuestions.forEach((question) => {
            let stepOfQuestion = form.steps.find(step => step.questions.includes(question));
            extraInfoString += "<br/>Step #" + (form.steps.indexOf(stepOfQuestion) + 1) + ": " + question.title;
        });

        if (allMissingRequiredQuestions.length > 0) {
            const config = {
                title: "Error in form",
                text: "Some required fields are not filled in or incorrectly filled in:" + extraInfoString,
                confirmButtonText: "OK"
            };
            Popup.show(config)
        } else {
            const config = {
                title: "Submit?",
                text: "Are you sure you want to submit this form? You can't change it afterwards",
                confirmButtonText: "Submit",
                cancelButtonText: "Cancel"
            };
            Popup.show(config, postFormAnswers)
        }
    }

    function showExitInspectionPopup() {

        function confirm() {
            AppStorage.setInspectionForm(null);
            AppStorage.setCurrentStepIndex(null);
            showDashboard();
        }

        const config = {
            title: "Close inspection",
            text: "You are about to close your unfinished inspection. Your entered data will be lost.<br/><br/> Do you want to continue?",
            confirmButtonText: "Close inspection",
            cancelButtonText: "Cancel"
        };

        Popup.show(config, confirm)
    }

    function showSmartSelectScreen(formFieldProps) {
        history.push("/inspection/smartselect/" + formFieldProps.field.id)
    }

    function showDashboard() {
        props.history.push("/dashboard");
    }
}

export default Inspection;