import React from "react";
import './header.scss'
import Icon from "../../util/Icon";

function Header(props) {

    const LeftIcon = () => {
        return props.leftIcon
            ? <div onClick={props.leftIconClick}><HeaderIcon iconName={props.leftIcon} /></div>
            : null
    };

    const RightIcon = () => {
        return props.rightIcon
            ? <div onClick={props.rightIconClick}><HeaderIcon iconName={props.rightIcon} /></div>
            : null
    };

    return (
        <div id="header">
            <div className="row with-margin">
                <div id="content">
                    <div className={"icon-container"}>
                        <LeftIcon />
                    </div>
                    <Title content={props.title}/>
                    <div className={"icon-container"}>
                        <RightIcon />
                    </div>
                </div>
            </div>
        </div>
    );
}

function HeaderIcon(props) {
    return (
        <Icon size={23} icon={props.iconName} className={'icon'} />
    )
}

function Title(props) {
    return (
        <h1>{props.content}</h1>
    )
}

export default Header;