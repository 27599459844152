import React from "react";
import './baselist.scss';
import NavigationBar from "../../components/navigationbar/NavigationBar";
import LoadingIndicator from "../../components/loadingindicator/LoadingIndicator";
import SideMenu from "../../components/sidemenu/SideMenu";
import Icon from "../../util/Icon";
import {Link, useHistory} from "react-router-dom";
import EmptyView from "../../components/emptyview/EmptyView";

/**
 * Make sure the data property is an array
 * @property data {array}
 */
function BaseList(props) {
    const history = useHistory()
    const title = props.title ?? "";
    const classNameAddition = props.className ? ` ${props.className}` : "";
    const leftIcon = props.showBackButton ? 'back-arrow' : 'menu';
    let setMenuVisible = null

    const PageContent = () => {
        const content = (!props.isLoading && props.data && props.data.length === 0) ? <EmptyView/> : props.list;
        return (
            <div className="page-content">
                {content}
            </div>
        )
    }

    return (
        <div id="base-list" className={`base-list${classNameAddition}`}>
            <NavigationBar title={title}
                           reference={true}
                           leftIcon={leftIcon}
                           leftIconClick={() => {
                               if(props.didTapBackButton) {
                                   props.didTapBackButton()
                               }
                               
                               if (props.showBackButton) {
                                   history.goBack();
                               } else {
                                   setMenuVisible(true)
                               }
                           }}
            />
            <LoadingIndicator isLoading={props.isLoading}
                              indicatorColor={"dark"}
                              showBackgroundOverlay={false}
                              isFullscreen={true}
            />
            <PageContent/>
            <SideMenu reference={true}
                      menuVisibleFunction={(setMenuVisibleFunction) => {
                          setMenuVisible = setMenuVisibleFunction
                      }}
            />
        </div>
    );
}

export function PDFListItem(props) {
    return (
        <a href={props.documentUrl} target={"_blank"} className={"list-item"}>
            <div className={"list-item-title"}>{props.title}</div>
            <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
        </a>
    )
}

export function TextPageListItem(props) {
    return (
        <Link to={props.url} className={"list-item"}>
            <div className={"list-item-title"}>{props.title}</div>
            <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
        </Link>
    )
}

export function ObjectListItem(props) {

    const clickAction = () => {
        props.onClick(props.object);
    }

    return (
        <div className={"list-item"} onClick={clickAction}>
            <div className={"list-item-title"}>{props.title}</div>
            <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
        </div>
    )
}

export default BaseList;