import JsonError from "./JsonError";
import JsonObject from "./JsonObject";

class JsonArray {
    constructor(json) {
        if(!Array.isArray(json)) {
            throw new JsonError("This is not a JsonArray");
        }

        this.jsonData = json;
    }

    get(index) {
        return this.jsonData[index];
    }

    getObj(index) {
        return new JsonObject(this.jsonData[index]);
    }
}

export default JsonArray;