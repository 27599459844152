import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import "./sass/main.scss";
import Home from "./home/Home";
import Login from "./login/Login";
import Dashboard from "./dashboard/Dashboard";
import Inspection from "./inspection/Inspection";
import SmartSelect from "./smartselect/SmartSelect";
import SmartSelectCreateOption from "./smartselect/smartselect-createoption/SmartSelectCreateOption";
import AppSelection from "./appselection/AppSelection";
import ProblematicWasteStreams from "./referenceapp/problematicwastestreams/ProblematicWasteStreams";
import AppStorage from "./util/AppStorage";
import Popup from "./components/popup/Popup";
import Api from "./util/Api";
import LoadingIndicator from "./components/loadingindicator/LoadingIndicator";
import AuthRoute from "./login/AuthRoute";
import Detail, {
    AboutDetail,
    DatabasesDetail,
    ProblematicWasteStreamDetail,
    ReferencesDetail
} from "./referenceapp/detail/Detail";
import About from "./referenceapp/about/About";
import References from "./referenceapp/references/References";
import Databases from "./referenceapp/database/Databases";
import CheckTheControls from "./referenceapp/checkthecontrols/CheckTheControls";

function App() {
    const [isAppUserRegistered, setIsAppUserRegistered] = useState(AppStorage.isAppGUIDRegistered());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(!isAppUserRegistered) {
            createAppUser();
        }
    }, []);

    if(!isAppUserRegistered) {
        return <LoadingIndicator isLoading={isLoading} indicatorColor={"dark"} showBackgroundOverlay={false}
                              isFullscreen={true}/>
    }

    return (
        <Router>
            <link rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic|PT+Sans+Narrow:regular,bold">
            </link>
            <div className="App">
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/appselection" exact component={AppSelection}/>
                    <Route path="/referenceapp/problematicwastestreams" exact component={ProblematicWasteStreams}/>
                    <Route path="/referenceapp/problematicwastestreams/:id" exact component={ProblematicWasteStreamDetail}/>
                    <Route path="/referenceapp/checkthecontrols" exact component={CheckTheControls}/>
                    <Route path="/referenceapp/references" exact component={References}/>
                    <Route path="/referenceapp/references/:id" exact component={References}/>
                    <Route path="/referenceapp/references/:id/detail/:detailId" exact component={ReferencesDetail}/>
                    <Route path="/referenceapp/databases" exact component={Databases}/>
                    <Route path="/referenceapp/databases/:id" exact component={Databases}/>
                    <Route path="/referenceapp/databases/:id/detail/:detailId" exact component={DatabasesDetail}/>
                    <Route path="/referenceapp/about" exact component={About}/>
                    <Route path="/referenceapp/about/:id" exact component={AboutDetail}/>
                    <Route path="/referenceapp/detail/:id" exact component={Detail}/>
                    <Redirect from={"/referenceapp/"} to={"/referenceapp/problematicwastestreams"}/>
                    <AuthRoute path="/dashboard" exact component={Dashboard}/>
                    <AuthRoute path="/inspection" exact component={Inspection}/>
                    <AuthRoute path="/inspection/smartselect/:id/create" component={SmartSelectCreateOption}/>
                    <AuthRoute path="/inspection/smartselect/:id" component={SmartSelect}/>
                    <Route path="/" exact component={Home}/>
                    <Redirect to={'/'}/>
                </Switch>
            </div>
        </Router>
    );

    function createAppUser() {
        let newAppGUID = AppStorage.generateAppGUID();

        function validator(response) {
        }

        function onSuccess(response) {
            setIsLoading(false);
            AppStorage.setRegisteredAppGUID(newAppGUID);
            setIsAppUserRegistered(true);
        }

        function onFailure(error) {
            setIsLoading(false);
            Popup.showDefaultRequestError(function () {
                createAppUser()
            })
        }

        const config = {
            data: {
                "firmware": "N/A",
                "phoneType": "Web",
                "appGUID": newAppGUID,
                "appVersionCode": process.env.REACT_APP_APP_VERSION,
                "appVersionName": process.env.REACT_APP_APP_VERSION_NAME
            }
        };

        setIsLoading(true);
        Api.post('/appuser', validator, onSuccess, onFailure, config);
    }
}

export default App;
