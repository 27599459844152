import React from "react";
import Icon from "../util/Icon";
import './home.scss'
import {Link} from "react-router-dom";
import DownloadAppHint from "../components/downloadapphint/DownloadAppHint";
import AppStorage from "../util/AppStorage";

function Home() {

    return (
        <div id="home" className="main">
            <div className="page-content row with-margin">
                <div className="logo-header">
                    <div className="logo-container">
                        <div className="logo-row">
                            <img alt="Sweap" className="circle-logo" src={require('../resources/sweap-circle.png')}/>
                            <img alt="Waste force" className="circle-logo" src={require('../resources/waste_force_circle.svg')}/>
                        </div>
                    </div>

                    <div className="title-wrapper">
                        <span className="title">Inspection & Reference App</span>
                    </div>
                </div>
                <DownloadAppHint/>
                <div className="button-footer">
                    <Link to={(AppStorage.isUserLoggedIn()) ? '/dashboard' : '/appselection'} className={"button"}>
                        <div className="text roboto-regular">Continue using web version</div>
                        <Icon size={14} icon="arrow-right" className={'icon'}/>
                    </Link>
                </div>
            </div>
            <div className={"footer"}>
                <div className={"footer-content row with-margin"}>
                    <img alt="Impel" className="circle-logo" src={require('../resources/impel-circle.png')}/>
                    <img alt="Life" className="circle-logo" src={require('../resources/life-circle.png')}/>
                </div>
            </div>
        </div>
    );
}

export default Home;