import React from "react";
import './appfeaturelist.scss'
import Icon from "../../util/Icon";

function AppFeatureList() {
    return (
        <div className="app-feature-list">
            <div className="row with-margin">
                <div className="wrapper">
                    <div className="title">What can the app offer you?</div>

                    <div className="feature">
                        <Checkmark/>
                        <div className="description">Save open inspections</div>
                    </div>
                    <div className="feature">
                        <Checkmark/>
                        <div className="description">View finalised inspections</div>
                    </div>
                    <div className="feature">
                        <Checkmark/>
                        <div className="description">Add picture to your inspection</div>
                    </div>
                    <div className="feature">
                        <Checkmark/>
                        <div className="description">Receive flagged inspections from colleagues</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Checkmark() {
    return (
        <Icon size={15} icon="checkmark" className='icon'/>
    )
}

export default AppFeatureList;