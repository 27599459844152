class CountryObject {
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
    }

    static parse(jsonObject) {
        const countryObject = new CountryObject();
        countryObject.parse(jsonObject);
        return countryObject;
    }
}
export default CountryObject;