import React, {useState} from "react";
import './steps.scss'
import Icon from "../../util/Icon";

function Steps(props) {
    const [stepCount] = useState(props.stepCount);

    let stepElementList = [];

    let activeStepIndex = props.activeStep;
    for (let stepIndex = 0; stepIndex < stepCount; stepIndex++) {
        let stepElement;
        let stepText = stepIndex + 1;
        if (stepIndex < activeStepIndex) {
            stepElement = (
                <div className="step completed" onClick={() => props.onStepClick(stepIndex)}>
                    <Icon size={15} icon="checkmark" className="icon" color="white"/>
                </div>
            )
        } else if (stepIndex === activeStepIndex) {
            stepElement = <div className={"step active"} onClick={() => props.onStepClick(stepIndex)}>{stepText}</div>
        } else {
            stepElement = <div className={"step"} onClick={() => props.onStepClick(stepIndex)}>{stepText}</div>
        }

        stepElementList.push(<div key={'step' + stepIndex}>{stepElement}</div>);

        if (stepIndex < stepCount - 1) {
            if (stepIndex < activeStepIndex) {
                stepElementList.push(<div key={'stepbar' + stepIndex} className="bar active"/>);
            } else {
                stepElementList.push(<div key={'stepbar' + stepIndex} className="bar"/>);
            }
        }
    }

    return (
        <div id="steps">
            <div className="page-content row with-margin">
                {stepElementList}
            </div>
        </div>
    );

}

export default Steps;