import { v4 as uuidv4 } from 'uuid';

let AppStorage = (function () {
    let isAppGUIDRegistered = function() {
        let appGUID = getRegisteredAppGUID();
        return !!appGUID && appGUID !== 'null';
    };

    let getRegisteredAppGUID = function () {
        return localStorage.getItem('registeredAppGUID');
    };

    let setRegisteredAppGUID = function (appGUID) {
        if(appGUID) {
            localStorage.setItem('registeredAppGUID', appGUID);
        } else {
            localStorage.removeItem('registeredAppGUID');
        }
    };

    let generateAppGUID = function () {
        return uuidv4();
    };

    let setAccessToken = function (accessToken) {
        if(accessToken) {
            localStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.removeItem('accessToken');
        }
    };

    let getAccessToken = function () {
        return localStorage.getItem('accessToken');
    };

    let setInspectionForm = function (inspectionForm) {
        if(inspectionForm) {
            let inspectionFormJson = JSON.stringify(inspectionForm);
            localStorage.setItem('inspectionForm', inspectionFormJson);
        } else {
            localStorage.removeItem('inspectionForm');
        }
    };

    let getInspectionForm = function () {
        let inspectionFormJson = localStorage.getItem('inspectionForm');
        if(inspectionFormJson) {
            let inspectionForm = JSON.parse(inspectionFormJson);
            return inspectionForm;
        }
        return null;
    };

    let setCurrentStepIndex = function (index){
        if(index) {
            localStorage.setItem('currentStepIndex', index);
        } else {
            localStorage.removeItem('currentStepIndex');
        }
    };

    let getCurrentStepIndex = function(){
        let step = localStorage.getItem('currentStepIndex');
        if(step){
            return parseInt(step);
        }
        return step;
    };

    let isUserLoggedIn = function() {
        return !!getAccessToken() && getAccessToken() !== 'null';
    };

    let logout = function (token) {
        setInspectionForm(null);
        setAccessToken(null);
        setRegisteredAppGUID(null);
        setCurrentStepIndex(null);
    };

    return {
        isUserLoggedIn: isUserLoggedIn,
        setAccessToken: setAccessToken,
        getAccessToken: getAccessToken,
        setInspectionForm: setInspectionForm,
        getInspectionForm: getInspectionForm,
        generateAppGUID: generateAppGUID,
        setRegisteredAppGUID: setRegisteredAppGUID,
        getRegisteredAppGUID: getRegisteredAppGUID,
        isAppGUIDRegistered: isAppGUIDRegistered,
        logout: logout,
        setCurrentStepIndex : setCurrentStepIndex,
        getCurrentStepIndex : getCurrentStepIndex,
    }
})();

export default AppStorage;
