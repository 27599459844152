import React from "react";
import Icon from "../../util/Icon";
import './searchview.scss'

export default function SearchView(props) {
    return <div key="search-input" className="search-view row with-margin">
        <Icon size={20} icon="search" className={'icon'}/>
        <input type="text" className="form-field-input text"
               placeholder="Search"
               autoFocus
               defaultValue={props.defaultValue}
               onChange={props.onChange}/>
    </div>;
}