import React from "react";
import "./smartselectcontent.scss"
import Icon from "../../util/Icon";
import LoadingIndicator from "../../components/loadingindicator/LoadingIndicator";

function SmartSelectContent(props) {
    if (props.isLoading) {
        return <LoadingIndicator indicatorColor={"dark"} showBackgroundOverlay={false} isFullscreen={true}/>
    }

    if (props.showHint) {
        return <div className="row with-margin minimum-character-hint">Minimum of 3 characters to search</div>
    }

    let selectedList = '';
    if (props.selectedItems.length > 0) {
        selectedList = <div key="selected-items" className="smart-select-list">
            <div className="list-title row">Selected {props.titlePlural}</div>
            <div className="list-items row">
                {props.selectedItems.map((smartSelectItem) => (
                    <div key={smartSelectItem.id} className="item-container selected"
                         onClick={() => props.onItemClick(smartSelectItem)}>
                        <div className="item-wrapper">
                            <div className="text-wrapper">
                                <div className="title">{smartSelectItem.title}</div>
                                <div className="subtitle">{smartSelectItem.subtitle}</div>
                            </div>
                            <Icon color="#45B255" size={20} icon="checkmark" className={'icon'}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>;
    }

    let unselectedList = '';
    if (props.unselectedItems.length > 0) {
        unselectedList = <div key="unselected-items" className="smart-select-list">
            <div className="list-title row">All {props.titlePlural}</div>
            <div className="list-items row">
                {props.unselectedItems.map((smartSelectItem) => (
                    <div key={smartSelectItem.id} className="item-container"
                         onClick={() => props.onItemClick(smartSelectItem)}>
                        <div className="item-wrapper">
                            <div className="title">{smartSelectItem.title}</div>
                            <div className="subtitle">{smartSelectItem.subtitle}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>;
    }

    let emptyView = '';
    if (props.unselectedItems.length === 0 && props.selectedItems.length === 0) {
        emptyView = <div className="row with-margin footer-empty" key="emptyView">
            <div className="text">No results found</div>
            {props.canCreateNewOption && <div className="button" onClick={props.onCreateClick}>Create new</div>}
        </div>;
    }

    return [selectedList, unselectedList, emptyView];
}

export default SmartSelectContent