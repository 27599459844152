import React from "react";
import './emptyview.scss'

export default function EmptyView(props) {

    const text = props.text ?? "No results.";

    return (
        <div className={"empty-view"}>
            <div className={"text"}>{text}</div>
        </div>
    )
}