class DatabaseObject {

    parse(jsonObject) {
        this.id = jsonObject.intNull("id");
        this.title = jsonObject.stringDefault("title", "");
        this.sortOrder = jsonObject.intDefault("sortOrder", 0);
    }

    static parse(jsonObject) {
        const reference = new DatabaseObject();
        reference.parse(jsonObject);
        return reference;
    }

    /** @function castToReferenceObject
     * Converts an already parsed referenceObject json structure to an actual ReferenceObject
     * E.g. This is used when an object is passed through the "props.location.state", it will then lose its type definition.
     * **/
    static castToDatabaseObject(jsonDatabaseObject) {
        return Object.assign(new DatabaseObject(), jsonDatabaseObject);
    }
}

export default DatabaseObject;