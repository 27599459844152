class WasteCodeDescription {
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.text = jsonObject.stringDefault("text", "");
    }

    static parse(jsonObject) {
        const wasteCodeDescription = new WasteCodeDescription();
        wasteCodeDescription.parse(jsonObject);
        return wasteCodeDescription;
    }
}
export default WasteCodeDescription;