import React, {useEffect, useState} from "react";
import './checkthecontrols.scss'
import LoadingIndicator from "../../components/loadingindicator/LoadingIndicator";
import BarWithButton from "../../components/barwithbutton/BarWithButton";
import NavigationBar from "../../components/navigationbar/NavigationBar";
import SideMenu from "../../components/sidemenu/SideMenu";
import Api from "../../util/Api";
import Popup from "../../components/popup/Popup";
import JsonObject from "../../util/json/JsonObject";
import CountryObject from "../../util/models/CountryObject";
import JsonError from "../../util/json/JsonError";
import AutoCompleteTextField from "./inputfields/AutoCompleteTextField";
import JsonArray from "../../util/json/JsonArray";
import WasteCodeObject from "../../util/models/WasteCodeObject";
import DropdownField from "./inputfields/DropdownField";

function CheckTheControls(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [countriesAndCodes, setCountriesAndCodes] = useState(null);
    const [selectedWasteCode, setSelectedWasteCode] = useState(null);
    const [introductionText, setIntroductionText] = useState(null);
    let setMenuVisible = null;

    var wasteCodeTimeout = null;

    let setWasteCodeOptionsVisible = null;
    let setWasteCodeLoadError = null;
    const [wasteCodeDescription, setWasteCodeDescription] = useState(null);
    const [countryDispatch, setCountryDispatch] = useState(null);
    const [countryDestination, setCountryDestination] = useState(null);
    // const [countryTransit, setCountryTransit] = useState(null);
    // const [disposalCode, setDisposalCode] = useState(null);

    useEffect(getInitialData, []);

    function PageContent() {
        return (
            <React.Fragment>
                <div className="page">
                    <div className="page-content">
                        <div className="row with-margin">
                            <div id="content">
                                { countriesAndCodes &&
                                <React.Fragment>
                                    { introductionText &&
                                        <div className="form-field">
                                            {introductionText}
                                        </div>
                                    }
                                    <AutoCompleteTextField
                                        name="waste-code"
                                        title="Waste code"
                                        placeholderText="Enter 3 characters to search..."
                                        currentValue={selectedWasteCode && {
                                            value: selectedWasteCode.id,
                                            label: selectedWasteCode.title,
                                            wasteCode: selectedWasteCode
                                        }}
                                        readOnly={false}
                                        emptyStateText="No results found."
                                        onLoadOptions={(inputValue, callback) => {
                                            wasteCodeTextChanged(inputValue, (wasteCodes) => {
                                                callback(wasteCodes.map(wasteCode => {
                                                    return {
                                                        value: wasteCode.id,
                                                        label: wasteCode.title,
                                                        wasteCode: wasteCode
                                                    }
                                                }))
                                            })
                                        }}
                                        onSelectItem={(selection) => {
                                            setWasteCodeDescription(null);
                                            setSelectedWasteCode(selection ? selection.wasteCode : null);
                                        }}
                                        optionsVisible={false}
                                        optionsVisibleFunction={(setOptionsVisibleFunction) => {
                                            setWasteCodeOptionsVisible = setOptionsVisibleFunction
                                        }}
                                        setLoadErrorFunction={(setWasteCodeLoadErrorFunction) => {
                                            setWasteCodeLoadError = setWasteCodeLoadErrorFunction
                                        }}
                                    />
                                    <DropdownField
                                        name="description"
                                        title="Description"
                                        currentValue={wasteCodeDescription && {
                                            value: wasteCodeDescription.id,
                                            label: wasteCodeDescription.text,
                                            description: wasteCodeDescription
                                        }}
                                        options={selectedWasteCode && selectedWasteCode.descriptions.map((description) => {
                                            return {
                                                value: description.id,
                                                label: description.text,
                                                description: description
                                            }
                                        })}
                                        readOnly={selectedWasteCode === null || selectedWasteCode.descriptions.length === 0}
                                        onSelectItem={(selection) => {
                                            setWasteCodeDescription(selection.description);
                                        }}
                                    />
                                    <DropdownField
                                        name="country-dispatch"
                                        title="Country of dispatch"
                                        currentValue={countryDispatch && {
                                            value: countryDispatch.id,
                                            label: countryDispatch.title,
                                            country: countryDispatch
                                        }}
                                        options={countriesAndCodes[0].map((country) => {
                                            return {
                                                value: country.id,
                                                label: country.title,
                                                country: country
                                            }
                                        })}
                                        onSelectItem={(selection) => {
                                            setCountryDispatch(selection.country);
                                        }}
                                    />
                                    <DropdownField
                                        name="country-destination"
                                        title="Country of destination"
                                        currentValue={countryDestination && {
                                            value: countryDestination.id,
                                            label: countryDestination.title,
                                            country: countryDestination
                                        }}
                                        options={countriesAndCodes[0].map((country) => {
                                            return {
                                                value: country.id,
                                                label: country.title,
                                                country: country
                                            }
                                        })}
                                        onSelectItem={(selection) => {
                                            setCountryDestination(selection.country);
                                        }}
                                    />
                                    {/*<DropdownField*/}
                                    {/*    name="country-transit"*/}
                                    {/*    title="Country of transit"*/}
                                    {/*    currentValue={countryTransit && {*/}
                                    {/*        value: countryTransit.id,*/}
                                    {/*        label: countryTransit.title,*/}
                                    {/*        country: countryTransit*/}
                                    {/*    }}*/}
                                    {/*    options={countriesAndCodes[0].map((country) => {*/}
                                    {/*        return {*/}
                                    {/*            value: country.id,*/}
                                    {/*            label: country.title,*/}
                                    {/*            country: country*/}
                                    {/*        }*/}
                                    {/*    })}*/}
                                    {/*    onSelectItem={(selection) => {*/}
                                    {/*        setCountryTransit(selection.country);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    {/*<DropdownField*/}
                                    {/*    name="disposal-code"*/}
                                    {/*    title="Recovering disposal code"*/}
                                    {/*    currentValue={disposalCode && {*/}
                                    {/*        value: disposalCode.id,*/}
                                    {/*        label: disposalCode.title,*/}
                                    {/*        disposalCode: disposalCode*/}
                                    {/*    }}*/}
                                    {/*    options={countriesAndCodes[1].map((code) => {*/}
                                    {/*        return {*/}
                                    {/*            value: code.id,*/}
                                    {/*            label: code.title,*/}
                                    {/*            disposalCode: code*/}
                                    {/*        }*/}
                                    {/*    })}*/}
                                    {/*    onSelectItem={(selection) => {*/}
                                    {/*        setDisposalCode(selection.disposalCode);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    { countriesAndCodes && <BarWithButton buttonTitle="Check controls" buttonClick={() => {
                        postCheckControls()
                    }}/> }
                </div>
            </React.Fragment>
        );
    }

    return (
        <div id="check-the-controls" className="main">
            <NavigationBar title="Check the controls"
                           reference={true}
                           leftIcon={"menu"}
                           leftIconClick={() => {
                               setMenuVisible(true);
                           }}
            />
            <LoadingIndicator isLoading={isLoading} indicatorColor={countriesAndCodes !== null ? "light" : "dark"} showBackgroundOverlay={countriesAndCodes !== null}
                              isFullscreen={countriesAndCodes !== null}/>
            <PageContent/>
            <SideMenu reference={true}
                      menuVisibleFunction={(setMenuVisibleFunction) => {
                          setMenuVisible = setMenuVisibleFunction
                      }}
            />
        </div>
    );

    function getInitialData() {
        function validator(response) {}

        function onSuccess(response) {
            setIsLoading(false)

            try {
                const jsonObject = new JsonObject(response.data);

                var countryList = [];
                var codeList = [];

                const countriesArray = jsonObject.obj("countries")
                if (countriesArray && countriesArray.jsonData && Array.isArray(countriesArray.jsonData)) {
                    countriesArray.jsonData.forEach((itemJsonData) => {
                        const itemJsonObject = new JsonObject(itemJsonData)
                        const countryObject = CountryObject.parse(itemJsonObject);
                        countryList.push(countryObject);
                    })
                }

                if (countryList.length === 0) {
                    throw JsonError("countries empty");
                }

                // const codesArray = jsonObject.obj("codes")
                // if (codesArray && codesArray.jsonData && Array.isArray(codesArray.jsonData)) {
                //     codesArray.jsonData.forEach((itemJsonData) => {
                //         const itemJsonObject = new JsonObject(itemJsonData)
                //         const codeObject = CodeObject.parse(itemJsonObject);
                //         codeList.push(codeObject);
                //     })
                // }
                //
                // if (codeList.length === 0) {
                //     throw JsonError("codes empty");
                // }

                // setCountriesAndCodes([countryList, codeList]);
                setCountriesAndCodes([countryList]);
                setIntroductionText(jsonObject.stringNull("description"));
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    getInitialData();
                },
                function () {
                    // Do nothing
                }
            )
        }

        setIsLoading(true);
        Api.get("checkcontrols", validator, onSuccess, onFailure);
    }

    function findWasteCodes(query, callback) {
        function validator(response) {}

        function onSuccess(response) {
            try {
                const jsonArray = new JsonArray(response.data);

                var wasteCodeList = [];

                jsonArray.jsonData.forEach((itemJsonData) => {
                    const itemJsonObject = new JsonObject(itemJsonData)
                    const countryObject = WasteCodeObject.parse(itemJsonObject);
                    wasteCodeList.push(countryObject);
                })

                setWasteCodeLoadError(false)

                callback(wasteCodeList)
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setWasteCodeLoadError(true)

            callback([])
        }

        Api.get("wastecode?q=" + encodeURI(query), validator, onSuccess, onFailure);
    }

    function postCheckControls() {
        // if (selectedWasteCode === null || wasteCodeDescription === null || countryDispatch === null
        //     || countryDestination === null || countryTransit === null || disposalCode === null) {
        // if (selectedWasteCode === null || wasteCodeDescription === null || countryDispatch === null
        //     || countryDestination === null || countryTransit === null) {
        if (selectedWasteCode === null || wasteCodeDescription === null || countryDispatch === null
            || countryDestination === null) {
            const config = {
                title: "Oops...",
                text: "Please fill in all fields.",
                confirmButtonText: "OK"
            };
            Popup.show(config, () => {});

            return
        }

        let body = {
            "wasteCode": selectedWasteCode.id,
            "description": wasteCodeDescription.id,
            "countryDispatch": countryDispatch.id,
            "countryDestination": countryDestination.id
            // "countryTransit": countryTransit.id
            // "disposalCode": disposalCode.id
        }

        function validator(response) {}

        function onSuccess(response) {
            setIsLoading(false)

            try {
                const jsonObject = new JsonObject(response.data);

                let title = jsonObject.string("title");
                let description = jsonObject.string("description");
                let footer = jsonObject.string("footer");

                const config = {
                    title: title,
                    text: "" + description + "<br /><br /><small>" + footer + "</small>",
                    confirmButtonText: "OK"
                };

                Popup.show(config, () => {});
            } catch (e) {
                onFailure(e);
            }
        }

        function onFailure(error) {
            setIsLoading(false)
            Popup.showDefaultRequestError(
                function () {
                    postCheckControls();
                },
                function () {
                    // Do nothing
                }
            )
        }

        setIsLoading(true);
        Api.post("checkcontrols", validator, onSuccess, onFailure, {}, body);
    }

    function wasteCodeTextChanged(inputValue, callback) {
        clearTimeout(wasteCodeTimeout);
        // self.wasteCodeRequest?.cancelRequest()

        if (inputValue.length >= 3) {
            setWasteCodeOptionsVisible(true)
            setWasteCodeLoadError(false)

            wasteCodeTimeout = setTimeout(() => {
                findWasteCodes(inputValue, callback)
            }, 500);
        } else {
            setWasteCodeOptionsVisible(false)
            setWasteCodeLoadError(false)

            callback([])
        }
    }
}

export default CheckTheControls;