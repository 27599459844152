import TextPage from "./TextPage";
import JsonObject from "../json/JsonObject";

class ReferenceObject {

    parse(jsonObject) {
        this.id = jsonObject.intNull("id");
        this.title = jsonObject.stringDefault("title", "");
        this.items = [];
        const itemArray = jsonObject.obj("items")
        if (itemArray && itemArray.jsonData && Array.isArray(itemArray.jsonData)) {
            itemArray.jsonData.forEach((itemJsonData) => {
                const itemJsonObject = new JsonObject(itemJsonData)
                const textPage = TextPage.parse(itemJsonObject);
                this.items.push(textPage);
            })
        }
    }

    static parse(jsonObject) {
        const reference = new ReferenceObject();
        reference.parse(jsonObject);
        return reference;
    }
    
    /** @function castToReferenceObject
     * Converts an already parsed referenceObject json structure to an actual ReferenceObject
     * E.g. This is used when an object is passed through the "props.location.state", it will then lose its type definition. 
     * **/
    static castToReferenceObject(jsonReferenceObject) {
        const referenceObject = Object.assign(new ReferenceObject(), jsonReferenceObject)
        let textPageItems = [];
        referenceObject.items.forEach(item => {
            const textPage = Object.assign(new TextPage(), item)
            textPageItems.push(textPage)
        })
        referenceObject.items = textPageItems
        return referenceObject
    }
}

export default ReferenceObject;