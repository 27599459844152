import React from "react";
import './barwithbutton.scss'

function BarWithButton(props) {
    return (
        <div className="bottom-bar">
            {!props.buttonLink && <button className="button" onClick={props.buttonClick}>{props.buttonTitle}</button>}
            {props.buttonLink && <a href={props.buttonLink} target={props.buttonLinkTarget} className="button">{props.buttonTitle}</a>}
        </div>
    );
}

export default BarWithButton;