class TextPage {
    parse(jsonObject) {
        // On TextPage calls id doesn't exist
        this.id = jsonObject.intNull("id");
        this.type = jsonObject.stringDefault("type", "");
        this.imageUrl = jsonObject.stringDefault("image", "");
        this.title = jsonObject.stringDefault("title", "");
        this.htmlText = jsonObject.stringDefault("html", "");
        this.buttonType = jsonObject.stringDefault("buttonType", "");
        this.buttonTitle = jsonObject.stringDefault("buttonTitle", "");
        this.buttonUrl = jsonObject.stringDefault("buttonUrl", "");
        this.documentUrl = jsonObject.stringDefault("document", "");
    }

    isTypePdf() {
        return this.type.toLowerCase() === "pdf";
    }

    isTypeTextPage() {
        return this.type.toLowerCase() === "textpage";
    }

    isButtonTypeUrl() {
        return this.buttonType.toLowerCase() === "url";
    }

    isButtonTypeDocument() {
        return this.buttonType.toLowerCase() === "pdf";
    }

    getRealButtonUrl() {
        if(this.isButtonTypeUrl()) {
            return this.buttonUrl;
        } else if(this.isButtonTypeDocument()) {
            return this.documentUrl;
        } else {
            return ""
        }
    }

    static parse(jsonObject) {
        const textPage = new TextPage();
        textPage.parse(jsonObject);
        return textPage;
    }
}
export default TextPage;