import React from "react";
import './form.scss'
import Question from "./question/Question";

function Form(props) {
    let fieldElements = [];
    let allQuestions = [];
    props.form.steps.forEach((step) => {
        allQuestions = allQuestions.concat(step.questions);
    });
    
    props.activeStep.questions.filter(q => filterQuestionsOnDependencies(allQuestions, q)).forEach(question => {
            fieldElements.push(<Question
                form={props.form}
                setIsLoading={props.setIsLoading}
                setIsLoadingOverlayEnabled={props.setIsLoadingOverlayEnabled}
                activeStep={props.activeStep}
                field={question}
                key={'step-' + props.activeStep.id + '-question-' + question.id}
                onFieldChange={props.onFieldChange}
                onClick={props.onClick}
                />);
        }
    );

    return (
        <div id="form">
            <div className="row with-margin">
                <div id="content">
                    {fieldElements}
                </div>
            </div>
        </div>
    );
}

export function filterQuestionsOnDependencies(allQuestions, question) {
    let passesThroughDependencies = true;

    if (Array.isArray(question.dependencies)) {
        question.dependencies.forEach((dependency) => {
            let dependentOnKey = dependency.key;
            let dependentOnValues = dependency.value;

            let decidingQuestion = allQuestions.find((decQuestion) => {
                    return decQuestion.dependencyKey === dependentOnKey;
                }
            );

            if (!decidingQuestion) {
                passesThroughDependencies = false
            }

            let correctlyFilledInDependingQuestion = dependentOnValues.find((possibleValue) => {
                let dependingQuestionValues = (decidingQuestion.value ? decidingQuestion.value : []);
                if (!Array.isArray(dependingQuestionValues)) {
                    dependingQuestionValues = [dependingQuestionValues];
                }
                return dependingQuestionValues.indexOf(possibleValue) > -1
            });

            if (!correctlyFilledInDependingQuestion) {
                passesThroughDependencies = false;
            }
        });
    }

    return passesThroughDependencies;
};

export default Form;