import React, {useEffect, useRef, useState} from "react";
import './question.scss'
import Popup from "../../../components/popup/Popup";
import Icon from "../../../util/Icon";

export const QUESTION_TYPES = ["gps", "text", "textarea", "datetime", "select", "radio", "checkbox", "smartselect"];

function Question(props) {
    let field = props.field;
    let fieldKey = props.key && 'step-' + props.activeStep.id + '-question-' + field.id;

    let labelElement = ((fieldType) => {
            switch (fieldType) {
                case "gps":
                case "text":
                case "textarea":
                case "datetime":
                case "select":
                case "smartselect":
                    return <label htmlFor={fieldKey}>{field.title}</label>;
                case "checkbox":
                case "radio":
                    return <label htmlFor={fieldKey} className="large">{field.title}</label>;
                default:
                    return null;
            }
        }
    )(field.type);

    //onChange returns a string, so if we want to correctly use radio, checkboxes and selects, we need to convert it to an int before posting the onChange value
    let onFieldChange = (f, v) => {
        if (v == null) {
            props.onFieldChange(f, v);
        } else {
            props.onFieldChange(f, isNaN(v) ? v : parseInt(v))
        }
    };

    let fieldElement = ((fieldType) => {
            switch (fieldType) {
                case "gps":
                    return <GPSField field={field} name={fieldKey}
                                     setIsLoading={props.setIsLoading}
                                     setIsLoadingOverlayEnabled={props.setIsLoadingOverlayEnabled}
                                     onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "text":
                    return <TextField field={field} name={fieldKey}
                                      onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "textarea":
                    return <TextAreaField field={field} name={fieldKey}
                                          onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "datetime":
                    return <DateTimeField field={field} name={fieldKey}
                                          onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "select":
                    return <SelectField field={field} name={fieldKey}
                                        onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "radio":
                    return <RadioField field={field} name={fieldKey}
                                       onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "checkbox":
                    return <CheckBoxField field={field} name={fieldKey}
                                          onChange={(event) => onFieldChange(field, event.target.value)}/>;
                case "smartselect":
                    return <SmartSelectField field={field} name={fieldKey} onClick={props.onClick}/>;
                default:
                    return null;
            }
        }
    )(field.type);

    function showTooltip() {
        const closeConfig = {
            title: field.title,
            text: field.tooltip,
            confirmButtonText: "OK"
        };
        Popup.show(closeConfig);
    }

    //\u00a0 is a nonbreaking space
    let isRequiredMark = <div className="required-mark">{field.required ? '\u00a0*' : ''}</div>;
    let tooltipIcon = field.tooltip && <div className="tooltip" onClick={showTooltip}/>;

    return (
        <div className="form-field">
            {<div className="label-holder">
                {labelElement}
                {isRequiredMark}
                {tooltipIcon}
            </div>}
            {fieldElement}
        </div>
    );
}

function GPSField(props) {
    const [locationString, setLocationString] = useState(props.field.value);
    let isMounted = true;

    //cleanup method
    useEffect(() => {
        return () => {
            isMounted = false;
        }
    }, []);


    return (<div>
            <div className={"form-field-input gps" + (props.field.readonly ? ' disabled' : '')}>
                <div className="location-text">{locationString}</div>
                <div className="icon-holder" onClick={() => {
                    if (!props.field.readonly) {
                        requestNewLocation()
                    }
                }}>
                    <Icon size={14} icon="refresh" className={'icon'} color="black"/>
                </div>
            </div>
        </div>
    );

    function requestNewLocation() {
        let options = {
            enableHighAccuracy: true,
            maximumAge: 0, //must be a new location
            timeout: 5 * 1000 //5 seconds
        };
        if (navigator.geolocation) {
            props.setIsLoadingOverlayEnabled(true)
            props.setIsLoading(true);
            navigator.geolocation.getCurrentPosition(onLocationUpdateSuccess, onLocationUpdateFailure, options);
        } else {
            onLocationUpdateFailure()
        }
    }

    function onLocationUpdateSuccess(location) {
        let lString = location.coords.latitude + ", " + location.coords.longitude;
        let changeEvent = {
            target: {
                value: lString
            }
        };
        props.onChange(changeEvent);
        props.setIsLoadingOverlayEnabled(false)
        props.setIsLoading(false);
        if (!isMounted) {
            return;
        }
        setLocationString(lString);
        //Faking a html onChange event to our listener
    }

    function onLocationUpdateFailure() {
        props.setIsLoadingOverlayEnabled(false)
        props.setIsLoading(false);
        if (!isMounted) {
            return;
        }
        setLocationString("Please enable location permission");
    }
}

function TextField(props) {
    return <input type="text"
                  disabled={props.field.readonly}
                  className={"form-field-input text" + (props.field.readonly ? ' disabled' : '')}
                  defaultValue={props.field.value}
                  onChange={props.onChange}/>
}

function TextAreaField(props) {
    return <textarea className={"form-field-input text-area" + (props.field.readonly ? ' disabled' : '')}
                     disabled={props.field.readonly}
                     defaultValue={props.field.value}
                     onChange={props.onChange}/>
}

function DateTimeField(props) {
    let valueToShow = null;
    if (props.field.value && !isNaN(props.field.value)) {
        valueToShow = props.field.value
    } else if (!props.field.readonly) {
        valueToShow = Date.now();
    }
    let date = valueToShow ? new Date(valueToShow) : new Date(); //start with timezone as offset

    //Set time to current time as default if no value is set
    useEffect(() => {
        if (!props.field.readonly && !props.field.value) {
            let changeEvent = {
                target: {
                    value: null
                }
            };
            changeEvent.target.value = date.getTime();
            props.onChange(changeEvent);
        }
    }, []);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dateString = `${year}-${month}-${day}`;

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const timeString = `${hours}:${minutes}`;

    const dateDOMRef = useRef();
    const timeDOMRef = useRef();

    return <div className="datetime-holder">
        <input type="date"
               ref={dateDOMRef}
               className={"form-field-input datetime" + (props.field.readonly ? ' disabled' : '')}
               disabled={props.field.readonly}
               defaultValue={valueToShow ? dateString : null}
               onChange={(e) => {
                   if (!props.field.readonly) {
                       dateStringToFieldValueBeforeOnChange(e);
                   }
               }}/>

        <input type="time"
               ref={timeDOMRef}
               className={"form-field-input datetime" + (props.field.readonly ? ' disabled' : '')}
               disabled={props.field.readonly}
               defaultValue={valueToShow ? timeString : null}
               onChange={(e) => {
                   if (!props.field.readonly) {
                       dateStringToFieldValueBeforeOnChange(e);
                   }
               }}/>
    </div>;

    function dateStringToFieldValueBeforeOnChange(event) {
        const inputDate = dateDOMRef.current.value;
        const inputTime = timeDOMRef.current.value;

        let inputDateTime = null;
        if (inputTime && inputDate) {
            inputDateTime = (new Date(Date.parse(inputDate + 'T' + inputTime + ':00'))).getTime();
        }

        let changeEvent = {
            target: {
                value: inputDateTime
            }
        };
        props.onChange(changeEvent);
    }
}

function SmartSelectField(props) {
    let smartSelectFieldValues = "";
    if (props && props.field && props.field.value) {
        smartSelectFieldValues = props.field.value.map(fieldValue => {
            return fieldValue.summary ?? ""
        });
        smartSelectFieldValues = smartSelectFieldValues.join(", ")
    }

    if (smartSelectFieldValues.length === 0) {
        smartSelectFieldValues = "— Select —";
    }

    return (
        <div className={"form-field-input smart-select" + (props.field.readonly ? ' disabled' : '')} onClick={() => {
            if (!props.field.readonly) {
                props.onClick(props)
            }
        }}>
            <div className="smart-select-options">
                {smartSelectFieldValues}
            </div>
            <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
        </div>
    )
}


function SelectField(props) {
    let options = props.field.options.map((option) => {
            return <option key={props.name + "-" + option.id} value={option.id}>{option.value}</option>
        }
    );

    options.unshift(<option className="placeholder" key={props.name + "-select"}
                            value={null}>— Select —
    </option>);

    return <div className={"select" + (props.field.readonly ? ' disabled' : '')}>
        <select className={"form-field-input" + (props.field.readonly ? ' disabled' : '')}
                defaultValue={props.field.value}
                disabled={props.field.readonly}
                onChange={props.onChange}>
            {options}
        </select>
        <Icon size={14} icon="arrow-right" className={'icon'} color="#444444"/>
    </div>
}

function RadioField(props) {
    let radioOptions = props.field.options.map((option) => {
            let optionKey = props.name + "-" + option.id;
            return <div key={optionKey} className="option">
                <input
                    defaultChecked={props.field.value === option.id}
                    id={optionKey}
                    type="radio"
                    disabled={props.field.readonly}
                    name={props.name}
                    value={option.id}/>

                <label htmlFor={optionKey}>
                    {option.value}
                </label>
            </div>
        }
    );

    return <fieldset className="radio form-field-input" onChange={props.onChange}>
        {radioOptions}
    </fieldset>
}

function CheckBoxField(props) {
    let selection = Array.isArray(props.field.value) ? props.field.value : [];

    let radioOptions = props.field.options.map((option) => {
            let optionKey = props.name + "-" + option.id;
            return <div key={optionKey} className="option">
                <input
                    defaultChecked={selection.includes(option.id)}
                    id={optionKey}
                    type="checkbox"
                    disabled={props.field.readonly}
                    name={props.name}
                    value={option.id}/>

                <label htmlFor={optionKey}>
                    {option.value}
                </label>
            </div>
        }
    );

    return <fieldset className="checkbox form-field-input" onChange={props.onChange}>
        {radioOptions}
    </fieldset>
}

export default Question;
