import React, {useEffect, useState} from "react";
import './login.scss'
import {useForm} from 'react-hook-form';
import Api from "../util/Api";
import AppStorage from "../util/AppStorage";
import ValidationError from "../util/ValidationError";
import {Redirect} from "react-router-dom";
import Popup from "../components/popup/Popup";
import LoadingIndicator from "../components/loadingindicator/LoadingIndicator";

function Login(props) {
    const {register, handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!AppStorage.isAppGUIDRegistered()) {
            createAppUser()
        }
    }, [])

    
    if (AppStorage.isUserLoggedIn()) {
        return (
            <Redirect to={'/dashboard'}/>
        );
    }

    return (
        <div id="login" className="main">

            <LoadingIndicator isLoading={isLoading} indicatorColor={"light"} showBackgroundOverlay={true}
                              isFullscreen={true}/>

            <div className="page-content row with-margin">
                <div className="logo-header">
                    <div className="logo-container">
                        <div className="logo-row">
                            <img alt="Impel" className="circle-logo" src={require('../resources/impel-circle.png')}/>
                            <img alt="Sweap" className="circle-logo" src={require('../resources/sweap-circle.png')}/>
                            <img alt="Life" className="circle-logo" src={require('../resources/life-circle.png')}/>
                        </div>
                    </div>

                    <div className="title-wrapper">
                        <span className="title">SWEAP</span>
                        <span className="subtitle">Inspection Form</span>
                    </div>
                </div>

                <form className="form" onSubmit={handleSubmit(login)}>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" ref={register} required/>
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" ref={register} required/>
                    </div>

                    <div className="i-agree">
                        <input type="checkbox" name="agree" id="agree" ref={register} required
                               onChange={(e) => document.getElementById('submit-button').disabled = !e.target.checked}/>
                        <label htmlFor="agree">I agree to the <a
                            href='https://content.tfsinspections.app/terms-and-conditions' target='_blank'><u>terms and
                            conditions</u></a></label>
                    </div>

                    <div className="footer">
                        <input className="button" type="submit" value="Login" id="submit-button" disabled/>
                        <div className="forgot-password-link">
                            <a href={process.env.REACT_APP_FORGOT_PASSWORD_URL}>Forgot password?</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    function login(loginCredentials) {

        function validator(response) {
            if (!response.data.accessToken) {
                throw new ValidationError("No access token found.")
            }
        }

        function onSuccess(response) {
            setIsLoading(false)
            AppStorage.setAccessToken(response.data.accessToken);
            props.history.push("/dashboard");
        }

        function onFailure(error) {
            setIsLoading(false)
            if (error.response.status === 401) {
                Popup.show({
                    title: "Login failed",
                    text: "Please check your credentials.",
                    confirmButtonText: "OK"
                })
            } else {
                Popup.showDefaultRequestError(function () {
                    login(loginCredentials)
                })
            }
        }

        let config = {
            data: {
                "username": loginCredentials.email,
                "password": loginCredentials.password,
                "acceptedTerms": loginCredentials.agree
            }
        };

        setIsLoading(true)
        Api.post('/login', validator, onSuccess, onFailure, config);
    }

    function createAppUser() {
        let newAppGUID = AppStorage.generateAppGUID();

        function validator(response) {
        }

        function onSuccess(response) {
            setIsLoading(false);
            AppStorage.setRegisteredAppGUID(newAppGUID);
        }

        function onFailure(error) {
            setIsLoading(false);
            Popup.showDefaultRequestError(function () {
                createAppUser()
            })
        }

        const config = {
            data: {
                "firmware": "N/A",
                "phoneType": "Web",
                "appGUID": newAppGUID,
                "appVersionCode": process.env.REACT_APP_APP_VERSION,
                "appVersionName": process.env.REACT_APP_APP_VERSION_NAME
            }
        };

        setIsLoading(true);
        Api.post('/appuser', validator, onSuccess, onFailure, config);
    }
}

export default Login;