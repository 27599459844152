import React from "react";
import './inputfields.scss'
import Select from 'react-select'

function DropdownField(props) {
    return (
        <div className="form-field">
            <div className="label-holder">
                <label htmlFor={props.name}>{props.title}</label>
            </div>
            <Select
                name={props.name}
                isDisabled={props.readOnly}
                className={props.name}
                classNamePrefix="impel-select"
                components={{
                    IndicatorSeparator: () => null
                }}
                defaultValue={props.currentValue}
                options={props.options}
                isSearchable={false}
                onChange={props.onSelectItem}
            />
        </div>
    );
}

export default DropdownField;